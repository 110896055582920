import * as React from 'react';
import queryString from 'query-string';
import useSWR from 'swr';
import { request } from 'graphql-request';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../index';

// Get the variables for the query
const variables =
  typeof window !== 'undefined'
    ? {
        id: queryString.parse(window.location.search).id,
      }
    : {};

const fetcher = (query) => request(process.env.GATSBY_WP_API, query, variables);

const HomePreview = () => {
  const { data, error } = useSWR(
    `
  query HomePreview($id: ID!) {
    wpPage: page(id: $id, idType: DATABASE_ID) {
      id
      title
      carousel {
        slides {
          title
          subTitle
          category
          image {
            sourceUrl
          }
        }
      }
    }
  }
  `,
    fetcher
  );

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return <Page data={data} />;
};

export default HomePreview;
